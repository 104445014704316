<script setup lang="ts">
import type { ImageFieldImage } from '@prismicio/client'
import ZoomIn from '@/assets/svg/zoom-in.svg?component'
import { useLightboxStore } from '@/store/lightboxState'

interface CustomImgRatioAttributes {
  h: number
  w: number
}

export interface ImageProps {
  imgObj: ImageFieldImage
  altFallback?: string | undefined
  loading?: 'lazy' | undefined
  hasFadeInEffect?: boolean
  hasLightbox?: boolean
  pictureSources?: PictureSource[]
  customImgTagStyles?: GenericCSSProperties | undefined
  customImgRatioAttributes?: CustomImgRatioAttributes
}

const props = withDefaults(defineProps<ImageProps>(), {
  imgObj: undefined,
  altFallback: undefined,
  loading: undefined,
  hasFadeInEffect: true,
  hasLightbox: false,
  pictureSources: () => [],
  customImgTagStyles: undefined,
  customImgRatioAttributes: undefined,
})

const GRACE_PERIOD_MS = 500
const reOrderedPictureSources = [...props.pictureSources]
reOrderedPictureSources.sort((a, b) => b.minWidth - a.minWidth)

const imgUrl = props.imgObj.url ? getCleanImgUrl(props.imgObj.url) : null

const imgOpacity = ref(0)

const indexForFallbackImg = reOrderedPictureSources.length - 1

if (props.loading === 'lazy' || !props.hasFadeInEffect) {
  /**
   * The @load event does not work with native loading="lazy".
   * In some cases we want to be able to omit the fade-in effect.
   */
  imgOpacity.value = 1
}

const showImg = () => {
  setTimeout(() => {
    // When the load event fires, the images are often not fully rendered on the screen. Therefore a short additional delay.
    imgOpacity.value = 1
  }, GRACE_PERIOD_MS)
}

const img = ref()
onMounted(() => {
  /**
   * Since @load only fires once, this is needed to add the loading animation each time.
   * https://github.com/nuxt/image/issues/682#issuecomment-1518473979
   */

  nextTick(() => {
    showImg()
  })
})

function getSourceSet(source: PictureSource) {
  const dpr1 = `${imgUrl}${getImgixQueryParams(source.imgQueryConfig)} 1x`

  const dpr2 = `, ${imgUrl}${getImgixQueryParams({
    ...source.imgQueryConfig,
    ...(source.imgQueryConfig.w ? { w: source.imgQueryConfig.w * 2 } : {}),
    ...(source.imgQueryConfig.h ? { h: source.imgQueryConfig.h * 2 } : {}),
  })} 2x`

  return dpr1 + dpr2
}

const setLightboxState = () => {
  useLightboxStore().setLightboxState(props.imgObj)
}
</script>

<template>
  <div v-if="imgUrl !== null" class="ui-image">
    <picture>
      <source
        v-for="(source, i) in reOrderedPictureSources"
        :key="`${i}source`"
        :media="`(min-width: ${source.minWidth}px)`"
        :srcset="getSourceSet(source)"
      />
      <img
        ref="img"
        :src="`${imgUrl}${getImgixQueryParams(
          reOrderedPictureSources[indexForFallbackImg].imgQueryConfig,
        )}`"
        :alt="props.imgObj?.alt ?? altFallback"
        :width="props.customImgRatioAttributes?.w ?? props.imgObj?.dimensions?.width"
        :height="props.customImgRatioAttributes?.h ?? props.imgObj?.dimensions?.height"
        :loading="props.loading"
        :style="{
          ...{ opacity: imgOpacity, transition: 'opacity 0.4s' },
          ...props.customImgTagStyles,
        }"
        @load="showImg"
      />
    </picture>
    <button v-if="props.hasLightbox" class="ui-image__btn" @click="() => setLightboxState()">
      <ZoomIn />
    </button>
  </div>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';
.ui-image {
  position: relative;
  &__btn {
    .button-reset();
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: transparent;
    transition: 0.15s background-color;
    width: 100%;

    svg {
      width: 5%;
      margin: 0 auto;
      opacity: 0;
      transition: 0.15s opacity;

      * {
        fill: #fff;
      }
    }

    &:hover {
      .supports-hover({
        background-color: var(--dark-transparent);

        svg {
          opacity: 1;
        }
      });
    }
  }
}
</style>
