import type { ImageFieldImage } from '@prismicio/client'
import { defineStore } from 'pinia'

export const useLightboxStore = defineStore('lightboxState', () => {
  const lightboxObject: Ref<ImageFieldImage | null> = ref(null)

  const setLightboxState = (value: ImageFieldImage | null) => {
    lightboxObject.value = value
  }

  return {
    setLightboxState,
    lightboxObject,
  }
})
